@tailwind base;
@tailwind components;
@tailwind utilities;

/* .react-calendar {
    width: 400px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    font-family: Arial, sans-serif;
  }
  
  .react-calendar__tile {
    color: #333;
  }
  
  .react-calendar__tile--active {
    background-color: #ccc;
  }
  
  .react-calendar__tile--now {
    background-color: #ff5722;
    color: #fff;
  } */